import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { dataObjectEvaluations,
         getQuestionTypeTitle,
         dataObjectEvaluationsQuestionBasics,
         dataObjectMultipleChoice,
         getTypeAssesment,
         dataObjectMultipleChoiceWithImage,
         dataObjectOpenAnswer } from '../../../helpers/functions';

import { ContentContext,
         ConfigurationContext } from '../../../context';

import { useNavigate, useParams } from 'react-router-dom';

import ButtonTextCustom from '../../../components/elements/ButtonTextCustom';
import ButtonCustom from '../../../components/elements/ButtonCustom';
import Form from '../../../components/Evaluations/Form';
import EvaluationContainer from '../../../components/Evaluations/EvaluationContainer';
import FormTopMessage from '../../../components/elements/FormTopMessage';
import FormBottomMessage from '../../../components/elements/FormBottomMessage';
import SuccessForm from '../../../components/elements/SuccessForm';
import successImage from '../../../assets/images/img_success_courses.svg';
import LoaderComponent from '../../../components/elements/LoaderComponent';

const WrapperStyled = styled.div`
    width: 100%;
    .box_evaluation_inner{
        display: flex;
        flex-direction: column;
        >.head_form{
            display: flex;
            flex-direction: column;
            color: ${props => `rgb(${props.pancolors.black})`};
            margin-bottom: 20px;
            margin-top: 15px;
            >.title{
                font-size: 22px;
                line-height: 26px;
                font-weight: 600;
            }
            >.name{
                font-size: 16px;
                line-height: 22px;
                margin-top: 5px;
                >.strong{
                    font-weight: 600;
                }
            }
        }
    }
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
`

const EditContainer = () => {

    const navigate = useNavigate();

    const { id_assessment, id_course, id_module } = useParams();

    const { basicData,
            assessment,
            setAssessment,
            getAssessment,
            module,
            getModule,
            course,
            getCourse,
            idCourseSelected } = useContext(ContentContext);

    const { panColors,
            userCountrySelected } = useContext(ConfigurationContext);
    
    const [ success, setSuccess ] = useState(false);
    const [ haveErrorForm, setHaveErrorForm ] = useState(false);
    const [ errorFormMessage, setErrorFormMessage ] = useState({top: "Faltan los datos remarcados", bottom: "Corrige información faltante o erronea marcada"});
    const [ initLoadEvaluation, setInitLoadEvaluation ] = useState(false);
    const [ contentByLang, setContentByLang ] = useState({});
    const [ loadingForm, setLoadingForm ] = useState(false);
    const [ assessmentType, setAssessmentType ] = useState(null);
    const [ idCourse, setIdCourse ] = useState(null);
    const [ idModule, setIdModule ] = useState(null);
    const [ titleSection, setTitleSection ] = useState({section: "", title: ""});
    const [ selectedLang, setSelectedLang ] = useState(null);

    const handleStartLoadEvaluation = () => {
        
        let haveError = false;
        let updateData = {...contentByLang};

        Object.keys(updateData).map(function(key) {
            
            if(updateData[key].required){
                
                if(!updateData[key].value.length){
                    updateData[key].error = true;
                    haveError = true;
                }else{
                    updateData[key].error = false;
                }
                
            }
            setContentByLang(updateData);
            
        })
        

        if(!haveError){
            
            if(assessment.type_assessment !== updateData.type_assessment.value){
                updateAssessmentType(updateData.type_assessment.value);
                return null;
            }else{
                updateAssessmentType(updateData.type_assessment.value);
                return null;
            }
            
        }

    }

    useEffect(() => {
        
        if((assessment && Object.keys(assessment).length) && Object.keys(basicData).length && Object.keys(userCountrySelected).length){
            handleInitForm();
            
        }

        return () => {}
    }, [assessment, basicData, userCountrySelected])

    const handleReestructure = () => {

        const type_assessment = getTypeAssesment(basicData.assessment_type, assessment.type_assessment);
        const keys = Object.keys(assessment.translations);
        const first_lang = keys[0]; //userCountrySelected.languages[0].language_identifier

        let question_quantity = assessment.translations[first_lang].questions.length;

        let assessmentUpdate_ = {...assessment};
        let translationsUpdate = {...assessmentUpdate_.translations};

        let questions = [];
        
        for (let i = 0; i < question_quantity; i++) {
            let question_el = {};
            question_el.translations = {}
            question_el.id_module = translationsUpdate[first_lang].questions[i].id_module;
            question_el.input_type = translationsUpdate[first_lang].questions[i].input_type;
            question_el.penalty_score = translationsUpdate[first_lang].questions[i].penalty_score;
            question_el.score = translationsUpdate[first_lang].questions[i].score;

            // Object.keys(assessment.translations).map(function(lang) {
            //     console.log("translationsUpdate", translationsUpdate[lang].questions[i])
            //     question_el.translations[lang] = translationsUpdate[lang].questions[i];
            // })
            let last_lang = "";
            userCountrySelected.languages.map((lang, index) => {
                
                if(translationsUpdate[lang.language_identifier]){
                    handleAddLanguage(lang, translationsUpdate[lang.language_identifier].questions[i]);
                    //console.log("--------------->", translationsUpdate[lang.language_identifier].questions[i])
                    question_el.translations[lang.language_identifier] = translationsUpdate[lang.language_identifier].questions[i];
                }else{
                    //handleAddLanguage(lang, {}, question_el.input_type.value, type_assessment, index, translationsUpdate[first_lang].questions[i]);
                    question_el.translations[lang.language_identifier] = handleAddLanguage(lang, {}, question_el.input_type.value, type_assessment, index, translationsUpdate[first_lang].questions[i]);
                }
            })
            
            questions.push(question_el);
        }

        return questions;

    }

    const handleAddLanguage = (lang, questions, input_type, type_assessment = null, index = null, translation_base) => {
        if(Object.keys(questions).length){

        }else{

            const secondary_actions = translation_base.secondary_actions;
            const data_struct = dataObjectEvaluationsQuestionBasics(null, null);
            let data_struct_update = {...data_struct};

            let question_structure;
            const secondary_actions_update = secondary_actions.map((secondary, index) => {
                switch (input_type) {
                    case "single_choice":
                    case "multiple_choice":
                        return dataObjectMultipleChoice(null, type_assessment);
                        break;
                    case "single_choice_image":
                    case "multiple_choice_image":
                        return dataObjectMultipleChoiceWithImage(null, (index+1), type_assessment);
                        break;
                    case "comments":
                        return dataObjectOpenAnswer(null);
                        break;
                            
                    default:
                        break;
                }
            })
            data_struct_update["secondary_actions"] = secondary_actions_update;

            return data_struct_update;
        }
    }

    const handleInitForm = () => {

        //let question_quantity = assessment.translations[userCountrySelected.languages[0].language_identifier].questions.length;

        const type_assessment = getTypeAssesment(basicData.assessment_type, assessment.type_assessment);
        setAssessmentType(type_assessment);

        let assessmentUpdate_ = {...assessment};
        let translationsUpdate = {...assessmentUpdate_.translations};
        
        const questions_formated = Object.keys(translationsUpdate).map(function(lang) {
            
            let questionUpdate = translationsUpdate[lang].questions.map((question, index) => {
                const secondary_actions = question.secondary_actions;
                const data_struct = dataObjectEvaluationsQuestionBasics(question, null);
                const data_struct_update = {...data_struct};

                const input = getQuestionTypeTitle(basicData.questions_type, data_struct_update.input_type.value);
                data_struct_update.input_type.value = input;
                const secondary_actions_update = secondary_actions.map((secondary, index) => {
                    switch (input) {
                        case "single_choice":
                        case "multiple_choice":
                            return dataObjectMultipleChoice(secondary, type_assessment);
                            break;
                        case "single_choice_image":
                        case "multiple_choice_image":
                            return dataObjectMultipleChoiceWithImage(secondary, (index+1), type_assessment);
                            break;
                        case "comments":
                            return dataObjectOpenAnswer(secondary);
                            break;
                                
                        default:
                            break;
                    }

                })
                data_struct_update["secondary_actions"] = secondary_actions_update;
                return data_struct_update;
            })
            translationsUpdate[lang].questions = questionUpdate;
            return questionUpdate;
        })
        
        const keys = Object.keys(assessment.translations);
        
        let assessmentUpdate = {...assessment};
        assessmentUpdate.question_quantity_toload = assessment.translations[keys[0]].questions.length;
        assessmentUpdate = {...dataObjectEvaluations(assessmentUpdate, "")};
        assessmentUpdate.translations = translationsUpdate;
        assessmentUpdate.segments = assessment.segments;
        assessmentUpdate.questions = handleReestructure();

        delete assessmentUpdate.title;
        delete assessmentUpdate.description;
        setContentByLang(assessmentUpdate);

    }
    

    useEffect(() => {
        if(userCountrySelected){
            if(userCountrySelected.languages.length){
                setSelectedLang(userCountrySelected.languages[0].language_identifier);
            }
        }
    return () => {}
    }, [userCountrySelected])


    const handleCancel = () => {
        if(id_course){
            navigate(`/course/${id_course}/evaluation/${assessment._id}/view`);
        }
        if(id_module){
            navigate(`/module/${id_module}/evaluation/${assessment._id}/view`);
        }
    }
    
    useEffect(() => {
        
        if(id_course){
            getCourse(id_course);
        }

        if(id_module){
            getModule(id_module);
        }

        return () => {}
    }, [id_course, id_module])

    useEffect(() => {
        
        if(course && selectedLang){
            setTitleSection({section: "Curso:", title: course.translations[selectedLang]?.title});
        }

        if(module && selectedLang){
            setTitleSection({section: "Contenido:", title: module.translations[selectedLang]?.title});
        }

        return () => {}
    }, [module, course, selectedLang])

    

    useEffect(() => {
        if(id_assessment && !(assessment && Object.keys(assessment).length)){
            getAssessment(id_assessment);
        }

        return () => {}
    }, [id_assessment, assessment])
    
    const updateAssessmentType = (type_assessment) => {
        
        const _type_assessment = getTypeAssesment(basicData.assessment_type, type_assessment);
        setAssessmentType(_type_assessment);
        
        let contentByLangUpdate = {...contentByLang};
        let translationsUpdate = {...contentByLangUpdate.translations};

        const questions_formated = Object.keys(translationsUpdate).map(function(lang) {
            let questionUpdate = translationsUpdate[lang].questions.map((question, index) => {
                const secondary_actions = question.secondary_actions;
                const secondary_actions_update = secondary_actions.map((secondary, index) => {
                    switch (question.input_type.value) {
                        case "single_choice":
                        case "multiple_choice":
                            secondary.go_to.required = _type_assessment === "evaluation_lineal" ? false : true;
                            break;
                        case "single_choice_image":
                        case "multiple_choice_image":
                            secondary.go_to.required = _type_assessment === "evaluation_lineal" ? false : true;
                            break;
                        default:
                            break;
                    } 
                    return secondary;
                    
                })

                question["secondary_actions"] = secondary_actions_update;
                return question;

            })
            translationsUpdate[lang].questions = questionUpdate;
            return questionUpdate;
        })

        contentByLangUpdate.translations = translationsUpdate;
        setContentByLang(contentByLangUpdate);
        setInitLoadEvaluation(true);

    }

    return (

        <WrapperStyled pancolors={panColors}>
            {!success ? 
                assessment && selectedLang?
                <>

                    <FormTopMessage 
                        haveError={haveErrorForm}
                        text={errorFormMessage.top}
                    />

                    <div className="box_evaluation_inner">
                        <div className='head_form'>
                            <div className="title">Evaluación</div>
                            <div className="name">{titleSection.section} <span className="strong">{titleSection.title}</span></div>
                        </div>
                    </div>

                    {!initLoadEvaluation ? 
                        <div className="box_init_form">
                            <Form 
                                lang={selectedLang}
                                data={contentByLang[selectedLang]}
                                scope="edit"
                                loadingForm={loadingForm}
                                setLoadingForm={setLoadingForm}
                                success={success}
                                contentByLang={contentByLang}
                                setContentByLang={setContentByLang}
                                assessment={assessment}
                                setAssessment={setAssessment}
                                assessmentType={assessmentType}
                                setAssessmentType={setAssessmentType}
                            />

                            <div className="actions">
                                <ButtonCustom 
                                    className="bt_submit"
                                    text={"Continuar editando la evaluación"}
                                    onClick={handleStartLoadEvaluation}
                                    style_type="normal"
                                    
                                    icon={null}
                                    background={{ normal: panColors.primary, hover: panColors.white }}
                                    border={{ normal: panColors.primary, hover: panColors.primary }}
                                    textcolor={{ normal: panColors.white, hover: panColors.primary }}
                                />
                                <ButtonTextCustom 
                                    className="bt_cancel"
                                    text="Cancelar"
                                    onClick={handleCancel}

                                    icon={null}
                                    background={{ normal: "", hover: "" }}
                                    border={{ normal: "", hover: "" }}
                                    textcolor={{ normal: panColors.red, hover: panColors.red }}
                                />
                            </div>
                        </div>
                        : ""
                    }

                    {initLoadEvaluation ? 
                        <div className="box_init_form">
                            <EvaluationContainer 
                                lang={selectedLang}
                                data={contentByLang}
                                scope="edit"
                                loadingForm={loadingForm}
                                setLoadingForm={setLoadingForm}
                                success={success}
                                setSuccess={setSuccess}
                                contentByLang={contentByLang}
                                setContentByLang={setContentByLang}
                                idCourse={idCourse}
                                idModule={idModule}
                                assessmentType={assessmentType}
                            />
                        </div>
                        : ""
                    }
                    
                    <FormBottomMessage 
                        className="form_error_bottom"
                        haveError={haveErrorForm}
                        text={errorFormMessage.bottom}
                    />
                </>
                :
                <div className='loader'>
                    <LoaderComponent />
                </div>    
                :
                <SuccessForm 
                    image={successImage} 
                    actionSuccessText={"Evaluación creada con éxito."}
                    resultSuccessText={"Ahora se puede encontrar esta evaluación activa."}
                    buttonBackToListText={`Volver al ${id_course ? "curso" : ""}${id_module ? "contenido" : ""}`}
                    buttonCreateSegmentationText={null}
                    buttonCreateNewText={null}
                    buttonBackToListStyle={"normal"}
                    buttonCreateNewStyle={null}
                    createNew={""}
                    createSegmentation={null}
                    backTo={`${id_course ? `/course/${idCourseSelected}/view` : ""}${id_module ? `/course/${idCourseSelected}/modules/${id_module}/view` : ""}`}
                    setSuccess={setSuccess}
                />
                
            }
        </WrapperStyled>
    );
}

export default EditContainer;